import React, { useState, Component } from 'react';
import Link from '@material-ui/core/Link';
import SectionGrid from './SectionGrid/SectionGrid';
import './SectionStage.css';

class SectionStage extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div id="SectionStage">
                {this.props.sections.map((section, index)=>{
                    return(
                        <SectionGrid key={index} title={section.title} desc={section.desc} work={section.work}></SectionGrid>)
                })}
            </div>
        )
    }
}
export default SectionStage