import React, { useState, Component } from 'react';
import { BrowserRouter as Router, Link as RouterLink, Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import ReactDOM from 'react-dom';
import Link from '@material-ui/core/Link';
import './SectionDetail.css';

/*props that need to be passed to section detail
title
all images
descriptions for each image
external links for the project
*/

/*
need to map gif as video,
png as img
txt as content
*/

function SectionDetail (props) {
        let {topicId} = useParams();
        return (
            <div className="SectionDetail">
                <div className="detailIntro">
                    <Link component={RouterLink} to="/" className="returnportfolio">
                        <span>Back To Portfolio</span>
                    </Link>
                    <h1>{props.project.title}</h1>
                    <p>{props.project.description}</p>
                </div>
                {props.project.content.map((contentpiece)=>{
                       return(
                            <div className="projectDetail">{contentpiece}</div>      
                        )  
                    }            
                )}
            </div>
        )
}
export default SectionDetail