import React from 'react'
import {
  Link as RouterLink,
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom'
import Link from '@material-ui/core/Link'
import SectionDetail from '../../SectionDetail/SectionDetail'
import './SectionGrid.css'

function SectionGrid (props) {
  let match = useRouteMatch()
  return (
    <Switch>
      {props.work.map(project => {
        return (
          <Route key={project.id} exact path={`${match.path}/${project.title}`}>
            <SectionDetail project={project}></SectionDetail>
          </Route>
        )
      })}
      <Route exact path={`${match.path}`}>
        <div className='SectionGrid'>
          <div className='gridTitleContent'>
            <h1>I Make Games!</h1>
          </div>
          <div className='games'>
            {props.work.map((project, index) => {
              return (
                <Link
                  component={RouterLink}
                  to={`${match.url}games/` + project.title}
                  className='game'
                  underLine='none'
                  key={index}
                >
                  <span className='gamePreview'>
                    <span
                      className='gameImg'
                      style={{
                        backgroundImage: 'url(' + project.thumbnail + ')'
                      }}
                    ></span>
                    <span className='gameFade'></span>
                    <span className='gameBorder'></span>
                  </span>
                  <span className='gameTitle'>{project.title}</span>
                </Link>
              )
            })}
          </div>
        </div>
      </Route>
    </Switch>
  )
}
export default SectionGrid
