import React, { Component } from 'react';
import {BrowserRouter as Router,Switch, Route} from "react-router-dom";
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import SectionStage from './Components/SectionStage/SectionStage';
import SectionGrid from './Components/SectionStage/SectionGrid/SectionGrid';

import jj1 from './assets/JJ/JJ-1.PNG';
import jj3 from './assets/JJ/JJ-3.mp4';
import jj5 from './assets/JJ/JJ-5.mp4';
import jj7 from './assets/JJ/JJ-7.mp4';
import jj8 from './assets/JJ/JJ-8.mp4';
import jj9 from './assets/JJ/JJ-9.mp4';

import dd1 from './assets/2D-CTRL/2D-1.jpg';
import dd3 from './assets/2D-CTRL/2D-3.mp4';
import dd5 from './assets/2D-CTRL/2D-5.mp4';

import sa1 from './assets/Stayin\' Alive/StayinAlive-1.PNG';
import sa3 from './assets/Stayin\' Alive/StayinAlive-3.mp4';
//images
const jj_1 = <img className="detailImage" src={jj1}></img>

const jj_3 = 
<video className="detailImage" controls autoPlay loop src={jj3}>
                            </video>
const jj_5 = <div className="projectDetail">
<video className="detailImage" controls autoPlay loop src={jj5}>
                            </video>
</div>
const jj_7 = 
<video className="detailImage" controls autoPlay loop src={jj7}>
                            </video>
const jj_8 = 
<video className="detailImage" controls autoPlay loop src={jj8}>
                            </video>
const jj_9 = 
<video className="detailImage" controls autoPlay loop src={jj9}>
                            </video>

const dd_1 = <img className="detailImage" src={dd1}></img>
const dd_3 = <video className="detailImage" controls autoPlay loop src={dd3}></video>
const dd_5 = <video className="detailImage" controls autoPlay loop src={dd5}></video>

const sa_1 = <img className="detailImage" src={sa1}></img>
const sa_3 = <video className="detailImage" controls autoPlay loop src={sa3}></video>
//text for site

const jj2 = <p className="detailContent">All the design work, code, and art in this game is my own. I wanted to keep "JJ's" simple, so the player's move-set is just run, jump, and climb. 
Celeste has been my design inspiration for this game. I love how much they explore their game's mechanics, and I tried to imitate their philosophy 
of designing levels as if they were rock climbing problems. Usually my levels are bite sized challenges that take a few moves to solve.</p>
const jj4 = <p className="detailContent">A major point of focus in this game has been scripting a snappy and responsive character controller. This is a movement heavy game,
so getting the controller right was very important. I implemented design features such as coyote time and jump buffering to give the game a better feel. 
After I had scripted my character controller I got to work designing levels and implementing my own pixel art. I like to build levels with multiple paths. 
In this level the player can take the route shown in the gif or they can climb around the outside edges of the stationary blocks rather than dodging the spikes.</p>;
const jj6 = <p className="detailContent">One interesting design challenege I encountered while developing this game is the player's lack of air control.
The main character in this game does not have the ability to double jump or air dash, like many other platforming
chatacters do, so jumping from a ledge is incredibly commital.  Outside of aerial drifting, the plyer does not
have much control once they have left the ground.  How do I make jumping fun for the fun for the player, when it
is the moment where they are the least involved in the game?  To tackle this problem, I created a variaty of
traversal mechanics that help give the control back to the player.  Rather than performing a simple jump the
player will have to use these new mechanics to traverse the level while avoiding obstacles.  This might mean
riding on a cycling platform, bouncing on a bouncer, or using their wall grab ability to ride a moving block.
These mechanics allow the player to have more moment to moment control, while also pushing the player to use
their abilities creatively to traverse the level.  This has the added effect of making each level seem more
freeform, as if the player is making things up as they go along.</p>;
const jj10 = <p className="detailContent">I have worked hard to add my own art to this game.  It just wouldn't have felt like it was my game
if I had continued to use someone else's art in it.  I had never made pixel art before so I had to
teach myself how.  I am pretty happy with how my sprites turned out considering this is my first attempt!
My main character (pictured above)  is based off of Jotaro Kujo, one of my favorite anime characters.</p>;

const dd2 = <p className="detailContent">A platformer is a about jumping and running.  As such, good movement is key.  I have worked hard
to program a character controller that feels great to move, with a variety of interesting mechanics
to design games around.</p>;
const dd4 = <p className="detailContent">I decided to make a custom physics controller, because I feel it is more responsive and I can fine
tune the character's movement better.  I have added some quality of life functionality like coyote
time (pictured above) and input buffering.  These make the game feel less punishing and make the 
controller more responsive to the player's intent.  I have used this controller in several projects
and I frequently tweak it to add new functionality.</p>;
const dd6 = <p className="detailContent">Along with input buffering and coyote time, this controller has a non parabolic jump curve.  The 
character rises slowly but falls quickly, this gives the player more air control and makes the 
character feel more snappy and responsive.  The character's jump height varies based on how long 
the player presses the jump button.

I also recently added wall jumping and wall sliding.  Much like with coyote time, I have scripted the controller
to allow the player a few frames to jump after letting go of the wall.

If you are interested, I wrote an article in my blog that goes into more depth about coyote time and
jump buffering, as well as my insights behind designing a good 2D controller.

<a href="https://medium.com/@jharoldcameron/you-need-to-implement-these-in-your-character-controller-b74bdd7f5d6"><p>Article Link</p></a>
<a href="https://github.com/James-Cameron/2D_CTRL"><p>Git Repo</p></a></p>;

const sa4 = <p className="detailContent">When I was designing the core gameplay loop, I decided that I wanted this to be a rage game. I designed it to be intentionally punishing and difficult.  You are given 4 lives and must dodge the bottles being thrown at you by the crowd.  If you run out of lives, it is game over but you must also keep playing the song by responding to the rhythm prompts.  If you don't clear a certain accuracy level in the rhythm section, you will game over as well.  It\'s very hard to keep up with the rhythm prompts and dodge the bottles at the same time.  I decided to balance some of this difficulty by adding a points system.  You can increase your score by timing the rhythms well, and if you increase your score enough you will be rewarded with extra lives.</p>
const sa2 = <p className="detailContent">I made a jam game with my friend for Ludum Dare 46!  The theme was "keep it alive". 'Stayin Alive' is a rhythm game with a twist.  You are a guitarist playing at a bar with a rough crowd. Dodge their beer bottles while playing music to make them happy.  Make sure to keep the vibe alive. I  designed and programmed the core gameplay and mechanics while my buddy handled the art and music."</p>


const JJcontent = [jj_1,jj2,jj_3,jj4,jj_5,jj6,jj_7,jj_8,jj_9,jj10];
const CTRLcontent = [dd_1,dd2,dd_3,dd4,dd_5,dd6];
const StayinAlivecontent = [sa_1,sa2,sa_3,sa4];

const work = [
  {
    title: "JJ's Big Day",
    description: "",
    content: JJcontent,
    thumbnail: jj1
  },
  {
    title: "Stayin' Alive",
    description: "",
    content: StayinAlivecontent,
    thumbnail: sa1
  },
  {
    title: "2D Character Controller",
    description: "",
    content: CTRLcontent,
    thumbnail: dd1
  }
]

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [{
        title: "My Games",
        work: work,
        desc: ""
      }],
    }
  }

  /*
  componentDidMount() {
    fetch("https://api.imgur.com/3/account/pbnjames/submissions/", {
      headers: {
        Authorization: "Client-ID 0b951d46ddb4a01"
      }
    }).then(res => res.json())
      .then(data => this.setState({
        sections: [{
          title: "My Games",
          work: data.data,
          desc: ""
        }]
      }, ()=>console.log(this.state.sections)))
}
  */
  
  render() {
    return (
      <div className="App">
        <div className="AppContent">
          <div className="ContentWrapper">
            <div className="LeftContent">
              <NavBar></NavBar>
            </div>

            <div className="RightContent">
              <Router>
                <Switch>
                  <Route exact path="/">
                    <SectionStage sections={this.state.sections}></SectionStage>
                  </Route>
                  <Route path="/games">
                    <SectionGrid title={this.state.sections[0].title} desc={this.state.sections[0].desc} work={this.state.sections[0].work}></SectionGrid>
                  </Route>
                </Switch>
              </Router>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default App;
