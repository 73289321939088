import React, { useState, Component } from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import BookIcon from '@material-ui/icons/Book';
import { Button } from '@material-ui/core';
import resume from '../../assets/Gaming_Resume_General_v01.pdf';
//import MailOutlineIcon from '@material-ui/icons/MailOutline';
import './NavBar.css';

class NavBar extends Component {

    render() {
        return (
            <div id="NavBar">
                <div className="navigation-links">
                    <a href="https://twitter.com/PBnJ4mes"><TwitterIcon fontSize="inherit"></TwitterIcon></a>
                    <a href="https://www.linkedin.com/in/pbnjamescameron/"><LinkedInIcon fontSize="inherit"></LinkedInIcon></a>
                    <a href="https://medium.com/@jharoldcameron"><BookIcon fontSize="inherit"></BookIcon></a>
                </div>
                <div className="jamesImage"></div>
                <div className="aboutBar">   
                    <div className="aboutContent">
                        <h1>About Me:</h1><br></br>
                        <h2>Hi! I'm James. I'm passionate about all types of game design and gameplay programming, especially when it comes to developing platformers and retro games. In my free time, I like playing competitive Melee, teaching myself guitar, and hanging out with my cat.</h2>
                        <br></br><br></br>
                        <h1>Favorite Games:</h1><br></br>
                        <h2>Celeste, Super Smash Bros Melee, Super Mario 64, Ratchet and Clank</h2>
                        <br></br>
                        <h2>For business inquiries, reach out to jharoldcameron@gmail.com</h2>
                        <br></br>
                        <a href={resume} download>
                          <Button variant ="contained" color="secondary">
                            My Resume
                            </Button>  
                        </a>
                        
                    </div>
                </div>  
            </div>
        )
    }

}
export default NavBar
